
// Write your custom style. Feel free to split your code to several files
#video-bg{
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translate(-50%, -50%);
}



@media (max-width: 991px) {
  .navbar-expand-lg .navbar-mobile {
    background-color: #000;
  }
  .backdrop-navbar {
    background-color: rgba(0, 0, 0, 0.95);
  }
}